import React from 'react'
import { Navigate } from 'react-router-dom';

const adminprotection = ({ element: Component, ...rest }) => {
    const username = localStorage.getItem('username');

    // Check if the username matches the admin credentials
    if (username === 'ddddsas') {
      return <Component {...rest} />;
    } else {
      return <Navigate to="/api/login" />;
    }
};

export default adminprotection
