import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LoginPage from './components/loginpage';
import SignupPage from './components/signuppage';
import Dashboard from '../src/components/dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Adminprotection from './components/adminprotection';
import Adminboard from './components/adminboard';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import NewPasswordSetPage from './components/NewPasswordSetPage';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/api/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/admin",
    element: <Adminprotection element={Adminboard} />,
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute element={Dashboard} />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/newpasswordset",
    element: <NewPasswordSetPage />,
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
