// public/components/NewPasswordSetPage.js
import React, { useState } from 'react';
import axios from 'axios';
import './newpasswordsetpage.css'; // Create this CSS file for styling

function NewPasswordSetPage() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      await axios.post('https://yourgreatscore.com/api/reset-password', { token, newPassword });
      setMessage('Password reset successfully');
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('An error occurred. Please try again.');
    }
  };
  
  // <div className='new-password-container'>
  //     <h1>Set New Password</h1>
  //     <form onSubmit={handleSubmit}>
  //       <div className='input-group'>
  //         <label htmlFor='new-password'>New Password:</label>
  //         <input
  //           type='password'
  //           id='new-password'
  //           value={newPassword}
  //           onChange={(e) => setNewPassword(e.target.value)}
  //           required
  //         />
  //       </div>
  //       <div className='input-group'>
  //         <label htmlFor='confirm-password'>Confirm Password:</label>
  //         <input
  //           type='password'
  //           id='confirm-password'
  //           value={confirmPassword}
  //           onChange={(e) => setConfirmPassword(e.target.value)}
  //           required
  //         />
  //       </div>
  //       <button type='submit'>Set New Password</button>
  //       {message && <p>{message}</p>}
  //     </form>
  //   </div>

  return (
      <div className="npbody">
        <div className="npwrapper">
          <form onSubmit={handleSubmit}>
            <h1>Set New Password</h1>
            <div className="npinput-box">
              <input
                type="password"
                placeholder='New Password'
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <i className='bx bxs-lock-alt'></i>
            </div>

            <div className="npinput-box">
              <input
                type="password"
                placeholder='Confirm Password'
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <i className='bx bxs-lock-alt'></i>
            </div>
            {message && <p>{message}</p>}
            <button type='submit'>Set New Password</button>
            
          </form>
        </div>
      </div>
  );
}

export default NewPasswordSetPage;
