import React from 'react'
import { useState, useEffect } from 'react'
import { useRef } from 'react'
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import axios from 'axios';
import './dashboardcss.css'

import dashboardicon from '../images/dashboard.png'
import mytaskicon from '../images/mytaskicon.png'
import notificationicon from '../images/notification.png'
import settingicon from '../images/setting.png'
import logouticon from '../images/logouticon.png'
import backarrow from '../images/backarrow.png'
import backarrowwithcolor from '../images/backarrowwithcolor.png'
import nextarrow from '../images/nextarrow.png'
import nextarrowwithcolor from '../images/nextarrowwithcolor.png'
import uploaddoc from '../images/uploaddoc.png'
import browsefile from '../images/browsefile.png'
import congratulationsimg from '../images/congratulations.png'
import dnotification from '../images/dnotification.png'
import nodocuments from '../images/nodocuments.png'
import threedot from '../images/sidethreedots.png'
import cardinput from '../images/cardinput.png'
import congratsimage from '../images/congratsimage.png'
import timerunning from '../images/timerunning.png'
import timecomplete from '../images/timecomplete.png'

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';


function Dashboard() {

  const [step, setStep] = useState(1);
  const [activeMenu, setActiveMenu] = useState('dashboard');
  const [havefile, setHavefile] = useState(false);
  const fileInputRef = useRef(null);
  const MAX_FILES = 6;
  const [currentBatch, setCurrentBatch] = useState(null);
  const [assignments, setAssignments] = useState([]);
  const myusername = localStorage.getItem('username');
  const [newName, setNewName] = useState('');
  const [myname, setMyname] = useState('');
  const [files, setFiles] = useState([]);
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    assignmentTitle: '',
    descriptionDetails: '',
    specialInstructions: '',
    requiredLinks: '',
    username: localStorage.getItem('username') || '', // Retrieve username from localStorage
  });

  const [remainingDays, setRemainingDays] = useState({});
  const [quoteIndex, setQuoteIndex] = useState(0);
  const quotes = [
    "Boost Your Academic Performance Effortlessly",
    "Get the Grades You Deserve, Hassle-Free",
    "Maximize Your Learning with Expert Solutions",
    "Get Expert Solutions for All Your Assignments",
    "Get Ahead with Professional Assignment Help",
    "Conquer Your Assignments with Confidence"
  ];

  const [menuVisible, setMenuVisible] = useState(false);
  const menuurRef = useRef(null);
  const [activeSection, setActiveSection] = useState('dashboard');


  const stripePromise = loadStripe('pk_test_51Q7IU706EdP9c4AHgfCdz0DDRLstN6lm5ozJhD8lWFfIyHjPhG1iDkfia3vZB0qcb8xyHy5ADGQEI4ihNkLDIcxm00I8lwKMFp');


  // ref
  const quoteref = useRef(null);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleNextClick = () => {

    // const assignmentTitlee = document.querySelector('.dassignmenttitle').value;
    // const descriptionDetailss = document.querySelector('.dformtextarea').value;

    // // Reset visibility of validation messages (assuming they have visibility set to 'hidden' by default)
    // const astitle3chara = document.querySelector('.dastitle3chara');
    // astitle3chara.style.visibility = 'hidden';
    // const des10word = document.querySelector('.ddes10word');
    // des10word.style.visibility = 'hidden';

    // Check assignment title length
    // if (assignmentTitlee.length < 3) {
    //   astitle3chara.style.visibility = 'visible';
    //   return; // Prevent further execution
    // }

    // // Check description word count
    // const wordCount = descriptionDetailss.split(' ').length;
    // if (wordCount < 10) {
    //   des10word.style.visibility = 'visible';
    //   return; // Prevent further execution
    // }
    const nextStep = step === 4 ? 1 : step + 1;
    setStep(nextStep);
  };

  const handleBackClick = () => {
    const prevStep = step === 1 ? 4 : step - 1;
    setStep(prevStep);
  };

  const handleLogout = (event) => {
    event.stopPropagation(); // Keep this line to prevent event bubbling

    const logoutMenu = document.querySelector('.dlougoutmenu');

    // Use GSAP to animate the visibility
    gsap.to(logoutMenu, {
      duration: 0.5,
      autoAlpha: 1,  // autoAlpha animates both opacity and visibility
      display: 'block',
    });

    // Hide the menu when clicking outside of it
    const mybody = document.querySelector('body');
    mybody.addEventListener('click', function (e) {
      if (e.target.id !== 'dlogout') {
        gsap.to(logoutMenu, {
          duration: 0.5,
          autoAlpha: 0,
          display: 'none',
        });
      }
    });
  };


  useEffect(() => {
    setHavefile(files.length > 0);
  }, [files]);

  const handleBrowsebutton = () => {

    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    if (files.length >= MAX_FILES) {
      alert(`You can only upload up to ${MAX_FILES} files.`);
      return;
    }

    const selectedFiles = Array.from(event.target.files);
    const fileDetails = selectedFiles.map(file => ({
      file: file,
      name: file.name.length > 30 ? file.name.slice(0, 30) + '...' : file.name,
      type: `.${file.name.split('.').pop()}`,
      size: (file.size / (1024 * 1024)).toFixed(2) + ' MB'
    }));

    setFiles(prevFiles => [...prevFiles, ...selectedFiles]); // Store only the files in state
  };


  const dlogoutyes = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    window.location.reload();
  };

  const dlogoutno = () => {
    const logoutMenu = document.querySelector('.dlougoutmenu');
    const logoutMenuu = document.querySelector('.dmlogoutmobilemenu');
    gsap.to(logoutMenu, {
      duration: 0.5,
      autoAlpha: 0,
      display: 'none',
    });
    gsap.to(logoutMenuu, {
      duration: 0.5,
      autoAlpha: 0,
      display: 'none',
    });
    // logoutMenu.style.visibility = 'hidden';
  };

  const dformdataupload = async (e) => {
    console.log('dformdataupload() called');
    // e.preventDefault();
    const form = new FormData();

    for (let key in formData) {
      form.append(key, formData[key]);
    }

    files.forEach(file => form.append('files', file));

    try {
      const res = await axios.post('https://yourgreatscore.com/api/assignments', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(res.data);
    } catch (err) {
      console.error(err.response.data);
    }
  };


  const dgobacktodashboard = () => {
    setActiveMenu('dashboard');
  };


  useEffect(() => {

    //   so when step is not equal to 1, I want you to hide all this components

  }, [step]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const username = localStorage.getItem('username');
        if (!username) {
          console.error('No username found in localStorage');
          return;
        }

        const response = await axios.get('https://yourgreatscore.com/api/user', {
          params: { username }, // Send username as a query parameter
          withCredentials: true,
        });

        setMyname(response.data.name); // Assuming the name is returned in the response
        setUserData(response.data); // Assuming the email and other data are returned in the response
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    fetch('https://yourgreatscore.com/api/user', {
      method: 'GET',
      credentials: 'include', // Include cookies
    })
      .then(response => response.json())
      .then(data => {
        // console.log(`Fetched currentBatch from backend in Dashboard: ${data.currentBatch}`);
        setCurrentBatch(data.currentBatch);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });

    if (myusername) {
      // Fetch assignments for the user
      fetch(`https://yourgreatscore.com/api/assignments/user/${myusername}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setAssignments(data);
        })
        .catch(error => {
          console.error('Error fetching assignments:', error);
        });
    }
  }, [myusername]);

  useEffect(() => {
    const interval = setInterval(() => {
      setQuoteIndex(prevIndex => (prevIndex + 1) % quotes.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  useGSAP(() => {
    gsap.from(quoteref.current, {
      duration: 1,
      // x: -100,
      opacity: 0,
      ease: 'power2.in',
    });

    //when user comback from another tab re-run the animation
    quoteref.current.style.opacity = 0;
    gsap.to(quoteref.current, {
      duration: 1,
      opacity: 1,
      ease: 'power2.in',
    });


  }, [quoteIndex]);

  const calculateRemainingDays = (submissionDate) => {
    const submission = new Date(submissionDate);
    const expectedCompletionDate = new Date(submission);
    expectedCompletionDate.setDate(submission.getDate() + 3);

    const today = new Date();
    const timeDiff = expectedCompletionDate - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    // If the daysDiff is less than 1, return "1 Day"
    return daysDiff > 1 ? `${daysDiff} Days` : '1 Day';
  };

  useEffect(() => {
    const updateRemainingDays = () => {
      const newRemainingDays = assignments.reduce((acc, assignment) => {
        acc[assignment._id] = calculateRemainingDays(assignment.submissionDate);
        return acc;
      }, {});
      setRemainingDays(newRemainingDays);
    };

    updateRemainingDays();

    const intervalId = setInterval(updateRemainingDays, 1000 * 60 * 60 * 24); // 24 hours

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [assignments]);

  const handleDownload = async (assignmentId) => {
    try {
      // Request the pre-signed URL from the backend
      const response = await axios.get(`https://yourgreatscore.com/api/download/${assignmentId}`, {
        withCredentials: true, // Include credentials if your backend requires authentication
      });

      if (response.status === 200 && response.data.url) {
        // Initiate the download by navigating to the pre-signed URL
        window.open(response.data.url, '_blank'); // Opens in a new tab
        // Alternatively, you can use window.location.href = response.data.url; to replace the current tab
      } else {
        alert('File not found or still in progress');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      if (error.response && error.response.status === 404) {
        alert('File not found');
      } else {
        alert('Error downloading file');
      }
    }
  };


  const handleNameChange = (event) => {
    setNewName(event.target.value);
  };

  const handleApplyClick = async () => {
    const username = localStorage.getItem('username');
    if (!username || !newName) {
      alert('Please enter a valid name');
      return;
    }

    try {
      const response = await axios.put('https://yourgreatscore.com/api/user/update-name', {
        username,
        newName,
      });

      if (response.status === 200) {
        setMyname(newName); // Update the name in the UI
        alert('Name updated successfully');
      }
    } catch (error) {
      console.error('Error updating name:', error);
      alert('Failed to update name');
    }
  };

  const handleMobileMenu = () => {
    setMenuVisible(prevVisible => !prevVisible);
  };

  useEffect(() => {
    if (menuVisible) {
      gsap.to(menuurRef.current, {
        duration: 0.3,
        opacity: 1,
        autoAlpha: 1,
        display: 'flex'
      });
    } else {
      gsap.to(menuurRef.current, {
        duration: 0.3,
        opacity: 0,
        display: 'none'
      });
    }
  }, [menuVisible]);

  const handleSectionClick = (section) => {
    setActiveSection(section);
    setActiveMenu(section);
  };

  const handleMobileLogout = () => {
    const logoutMenuu = document.querySelector('.dmlogoutmobilemenu');
    gsap.to(logoutMenuu, {
      duration: 0.5,
      autoAlpha: 1,
      display: 'block',
    });
  };

  useEffect(() => {
    gsap.to('.dmdashboard', { opacity: activeSection === 'dashboard' ? 1 : 0.7, duration: 0.5 });
    gsap.to('.dmmytasks', { opacity: activeSection === 'mytasks' ? 1 : 0.7, duration: 0.5 });
    gsap.to('.dmnotifications', { opacity: activeSection === 'notifications' ? 1 : 0.7, duration: 0.5 });
    gsap.to('.dmsettings', { opacity: activeSection === 'settings' ? 1 : 0.7, duration: 0.5 });
    gsap.to('.dmlogout', { opacity: activeSection === 'logout' ? 1 : 0.7, duration: 0.5 });
  }, [activeSection]);




  return (
    <div className="dmaindashboard">

      <div className="ddashboard">

        <div className="dsidebar">


          <div className="duserinfo">
            <div className="davatar">{localStorage.getItem('username').charAt(0).toUpperCase()}</div>
            <div className="dusername">{localStorage.getItem('username')}</div>
          </div>

          <ul className="dmenu">
            <li
              className={`dmenuitem ${activeMenu === 'dashboard' ? 'active' : ''}`}
              onClick={() => handleMenuClick('dashboard')}
            >
              <img src={dashboardicon} alt="dashboardicon" />
              <p>Dashboard</p>
            </li>
            <li
              className={`dmenuitem ${activeMenu === 'mytasks' ? 'active' : ''}`}
              onClick={() => handleMenuClick('mytasks')}
            >
              <img src={mytaskicon} alt="mytaskicon" />
              <p>My Tasks</p>
            </li>
            <li
              className={`dmenuitem ${activeMenu === 'notifications' ? 'active' : ''}`}
              onClick={() => handleMenuClick('notifications')}
            >
              <img src={notificationicon} alt="notificationicon" />
              <p>Notifications</p>
            </li>
          </ul>

          {/* <div className="content"> */}
          {/* {activeMenu === 'dashboard' && <div>Dashboard Content</div>}
        {activeMenu === 'mytasks' && <div>My Tasks Content</div>}
        {activeMenu === 'notifications' && <div>Notifications Content</div>} */}
          {/* </div> */}

          <ul className="bottommenu">
            <li
              className={`dmenuitem ${activeMenu === 'settings' ? 'active' : ''}`}
              onClick={() => handleMenuClick('settings')}
            >
              <img src={settingicon} alt="settingicon" />
              <p>Settings</p>
            </li>

            <li
              id='dlogout'
              className={`dmenuitem ${activeMenu === 'logout' ? 'active' : ''}`}
              onClick={handleLogout}
            >
              <img src={logouticon} alt="logouticon" onClick={handleLogout} />
              <p onClick={handleLogout}>Logout</p>
            </li>
          </ul>

        </div>

        <div className="dquotebar">

          <div className="quotecontainer">
            <p className="quote" ref={quoteref}>{quotes[quoteIndex]}</p>
            <button className="newtaskbutton"
              onClick={() => window.location.reload()}>New Task</button>
          </div>

        </div>

        <div className="dmainscreen">


          {activeMenu === 'dashboard' && (
            <>
              <div className="dstepline">
                <div className={`dstepcircle ${step === 1 ? 'active' : ''}`}>1</div>
                <div className={`dstepcircle ${step === 2 ? 'active' : ''}`}>2</div>
                <div className={`dstepcircle ${step === 3 ? 'active' : ''}`}>3</div>
                <div className={`dstepcircle ${step === 4 ? 'active' : ''}`}>4</div>
              </div>

              <div className="dstepline2">
                <div className="ddetail1">Enter Details</div>
                <div className="ddetail2">Upload Files</div>
                <div className="ddetail3">Payment Info</div>
                <div className="ddetail4">Confirmation</div>
              </div>

              {step === 1 && (
                <>
                  <div className="dformcontainer">
                    <h3>Assignment Title</h3>
                    <h3>Special Instructions</h3>
                  </div>

                  <div className="dformcontainer2">

                    <input type="text" placeholder="Enter Assignment Title" className='dassignmenttitle'
                      name="assignmentTitle"
                      value={formData.assignmentTitle}
                      onChange={handleInputChange} />

                    <input type="text" placeholder="Enter Special Instructions"
                      name='specialInstructions'
                      value={formData.specialInstructions}
                      onChange={handleInputChange} />
                  </div>

                  <h3 className='dformdescription'>Description</h3>
                  <textarea className="dformtextarea" placeholder="Enter Description"
                    name="descriptionDetails"
                    value={formData.descriptionDetails}
                    onChange={handleInputChange}></textarea>

                  <h3 className="dformrequiredlinks">Required Links</h3>
                  <input type="text" placeholder="Enter Links" className='dforminputlink'
                    name='requiredLinks'
                    value={formData.requiredLinks}
                    onChange={handleInputChange} />

                  <div className="dformbuttons">
                    {step === 1 ? (
                      <img
                        src={backarrow}
                        alt="Back"
                        className="dbutton"
                        style={{ cursor: 'default' }}
                      />
                    ) : (
                      <img
                        src={backarrowwithcolor}
                        alt="Back"
                        className="dbutton"
                        onClick={handleBackClick}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <img
                      src={step === 4 ? nextarrow : nextarrowwithcolor}
                      alt="Next"
                      className="dbutton"
                      onClick={handleNextClick}
                    />
                  </div>
                </>
              )}

              {step === 2 && (
                <div className="dstep2form">
                  {!havefile && (
                    <>
                      <img src={uploaddoc} alt="Upload Documents" className='duploadimg' />
                      <h4 className='dfileh4'>Drag & Drop Files Here</h4>
                      <h4 className='dfileh4'>or</h4>
                    </>
                  )}

                  {havefile && (
                    <div className="table-container">
                      <table className="file-table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Size</th>
                          </tr>
                        </thead>
                        <tbody>
                          {files.map((file, index) => (
                            <tr key={index}>
                              <td>{file.name}</td>
                              <td>{file.type}</td>
                              <td>{file.size}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  <button className="dbrowsefile" onClick={handleBrowsebutton}
                    style={{ marginTop: havefile ? '1rem' : '0' }}
                  >Browse File</button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    className='dinputbrowsefile'
                    multiple />

                  <div className="dformbuttons">
                    {step === 1 ? (
                      <img
                        src={backarrow}
                        alt="Back"
                        className="dbutton"
                        style={{ cursor: 'default' }}
                      />
                    ) : (
                      <img
                        src={backarrowwithcolor}
                        alt="Back"
                        className="dbutton"
                        onClick={handleBackClick}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <img
                      src={step === 4 ? nextarrow : nextarrowwithcolor}
                      alt="Next"
                      className="dbutton"
                      onClick={handleNextClick}
                    />
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className="dstep3form">
                  <Elements stripe={stripePromise}>
                    <PaymentForm
                      onSuccess={() => {
                        // Submit assignment data and move to the next step
                        dformdataupload();
                        handleNextClick();
                      }}
                      onError={(error) => {
                        // Handle payment error
                        console.error('Payment Error:', error);
                      }}
                    />
                  </Elements>

                  <div className="dformbuttons">
                    {step === 1 ? (
                      <img
                        src={backarrow}
                        alt="Back"
                        className="dbutton"
                        style={{ cursor: 'default' }}
                      />
                    ) : (
                      <img
                        src={backarrowwithcolor}
                        alt="Back"
                        className="dbutton"
                        onClick={handleBackClick}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </div>
                </div>
              )}


              {/* <div className="dformbuttons">
                    {step === 1 ? (
                      <img
                        src={backarrow}
                        alt="Back"
                        className="dbutton"
                        style={{ cursor: 'default' }}
                      />
                    ) : (
                      <img
                        src={backarrowwithcolor}
                        alt="Back"
                        className="dbutton"
                        onClick={handleBackClick}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <img
                      src={step === 4 ? nextarrow : nextarrowwithcolor}
                      alt="Next"
                      className="dbutton"
                      onClick={handleNextClick}
                    />
                  </div> */}

              {step === 4 && (
                <div className="dstep4form">
                  <img src={congratulationsimg} alt="" />
                  <h1>Assignment uploaded successfully!</h1>
                  <h2>Your assignment details have been submitted </h2>
                  <h2>for evaluation. Expect feedback soon.</h2>
                  <button className="dhomebutton">Go Home</button>
                  {/* <button className="dsubmithome">submit</button> */}
                </div>
              )}
            </>
          )}


          {/* My Task */}

          {activeMenu === 'mytasks' && (
            <div className="dmytasks">

              {assignments.length <= 0 ? (

                <>
                  <img src={nodocuments} alt="" className='dnodocuments' />
                  <h1>Add New Assignment</h1>
                  <h3>Go back to Dashboard To Add New Task</h3>
                  <button className='dmytaskdashboard' onClick={dgobacktodashboard}>Dashboard</button>
                </>
              ) : (

                //if currentBatch > 0
                <div className="table-container2">
                  <table className="assignment-table">
                    <thead>
                      <tr>
                        <th>Assignment Title</th>
                        <th>Submission Date</th>
                        <th>Status</th>
                        <th>Expected Completion Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assignments.map((assignment) => (
                        <tr key={assignment._id}>
                          <td>{assignment.assignmentTitle}</td>
                          <td>{assignment.submissionDate}</td>
                          <td>
                            {/* dinprogress */}
                            {assignment.Complete ? (
                              <button
                                className='dinprogress'
                                onClick={() => handleDownload(assignment.assignmentId)}
                                style={{ cursor: 'pointer' }}
                              >
                                Download
                              </button>
                            ) : (
                              <button className='dinprogress' disabled>
                                In Progress
                              </button>
                            )}
                          </td>
                          <td>{remainingDays[assignment._id]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>


              )}
            </div>
          )}




          {activeMenu === 'notifications' && (
            <div className="dnotificationmenu">
              <img src={dnotification} alt="" />
              <h1>No new notifications received</h1>
              <h2>Check again later for upcoming updates</h2>
              <button className="dgoback">Go Back</button>
            </div>
          )}

          {activeMenu === 'settings' && (
            <div className="dsettingsmenu">
              <h4 className='dwelcomename'>Welcome {myname}</h4>
              <h5 className='dmemberactive'>Membership Status: Active</h5>

              <div className="dunderstepline"></div>

              <div className="demailinfo">
                <h3 className='duseremail'>User Email</h3>
                {userData && (
                  <h3 className='demail'>{userData.email}</h3>
                )}
              </div>

              <div className="dunderstepline"></div>

              <div className="dusernameedit">
                <h3 className='dusercontact'>User Contact</h3>
                <h3 className='dfullname'>Full Name*</h3>
                <input type="text" placeholder="Enter Full Name" className='dinputfullname'
                  value={newName}
                  onChange={handleNameChange} />
              </div>

              <button className='dchangenamebutton'
                onClick={handleApplyClick}>Apply</button>
            </div>
          )}

        </div>

        <div className="dlougoutmenu">
          <h1>Are you sure you want to logout?</h1>
          <button className="dlogoutbutton" onClick={dlogoutyes}>Yes</button>
          <button className="dcancelbutton" onClick={dlogoutno}>No</button>
        </div>

        <p className='dastitle3chara'>Enter at least 3 characters</p>
        <p className='ddes10word'>Enter at least 10 words</p>

      </div>

      <div className="dmobilework">

        <div className="dmheader">
          <img src={threedot} alt=""
            onClick={handleMobileMenu} />
          <p>New Task</p>
          <p>Save</p>
        </div>

        {activeMenu === 'dashboard' && (
          <>
            <div className="dmfourdots">
              <div className={`dmmydot ${step === 1 ? 'active' : ''}`}></div>
              <div className={`dmmydot ${step === 2 ? 'active' : ''}`}></div>
              <div className={`dmmydot ${step === 3 ? 'active' : ''}`}></div>
              <div className={`dmmydot ${step === 4 ? 'active' : ''}`}></div>
            </div>


            {step === 1 && (
              <div className="dminputdataform">

                <label className='.dmastitle'>Assignment Title</label>
                <input type="text" placeholder="Maths Lab 3" className='dminput1'
                  name="assignmentTitle"
                  value={formData.assignmentTitle}
                  onChange={handleInputChange} />

                <label className='dmlabel2'>Description</label>
                <textarea className="dminput2" placeholder="Please include all the description required to complete the assignment Thanks!"
                  name="descriptionDetails"
                  value={formData.descriptionDetails}
                  onChange={handleInputChange}></textarea>

                <label className='dmlabel3'>Special Instructions</label>
                <input type="text" placeholder="Other Information" className='dminput3'
                  name='specialInstructions'
                  value={formData.specialInstructions}
                  onChange={handleInputChange} />

                <label className='dmlabel4'>Required Links</label>
                <input type="text" placeholder="https://github.com/ws1/" className='dminput4'
                  name='requiredLinks'
                  value={formData.requiredLinks}
                  onChange={handleInputChange} />
              </div>
            )}

            {step === 2 && (
              <>
                <div className="dmbrowsecontainer">
                  <p className='dmdragtext'>Drag / Drop Files Here</p>
                  <p className='dmortext'>Or</p>

                  <input
                    type="file"
                    id="fileInput"
                    className="dmbrowseinput"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                  <label htmlFor="fileInput" className="dmbrowselabel">
                    Browse Files
                  </label>
                </div>

                {files.length > 0 && (
                  <div className="dmdisplayfiles">
                    {Array.from(files).map((file, index) => (
                      // if file name is more than 15 characters display only 15 characters
                      <p key={index}>* {file.name.length > 15 ? file.name.slice(0, 15) + '...' : file.name}</p>
                    ))}
                  </div>
                )}

              </>
            )}

            {step === 3 && (
              <div className="dstep3form">
                <Elements stripe={stripePromise}>
                  <PaymentForm
                    isMobile={true}
                    onSuccess={() => {
                      // Submit assignment data and move to the next step
                      dformdataupload();
                      handleNextClick();
                    }}
                    onError={(error) => {
                      // Handle payment error
                      console.error('Payment Error:', error);
                    }}
                  />
                </Elements>
              </div>
            )}


            {step === 4 && (
              <>
                <div className="congratsdiv">
                  <img src={congratsimage} alt="" />
                  <h3>Assignment uploaded<br></br> successfully!</h3>
                  <p>Your assignment details have been submitted for evaluation. Expect feedback soon.</p>
                  <button>New Task</button>
                </div>
              </>
            )}

            <div className="dmtwobuttons"
              style={{
                display: step === 3 ? 'none' : 'visible',
                marginTop: step === 2 ? '20rem' : step === 4 ? '25rem' : '0'
              }}>
              <button className='dmpreviousbutton' onClick={handleBackClick}>Previous</button>
              <button className='dmnextbutton' onClick={handleNextClick}>Next</button>
            </div>

          </>
        )}

        {activeMenu === 'mytasks' && (
          <>
            {assignments.length <= 0 ? (
              <>
                <div className="dmnodocuments">
                  <img src={nodocuments} alt="" />
                  <h1>Add New Task</h1>
                  <h3>Go back to Dashboard To <br />Add New Task</h3>
                  <button className="add-button">
                    <span className="plus-icon">+</span>
                    Add
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="assignments-list">
                  {assignments.map((assignment) => (
                    <div key={assignment._id} className="assignment-item">

                      <div className="assignment-info">
                        <div className="dmtimecover">
                          <img src={timerunning} alt="In Progress" className="assignment-icon" />
                        </div>
                        <div>
                          <h3>{assignment.assignmentTitle}</h3>
                          <p>View Details</p>
                        </div>

                      </div>
                      {/* status-button */}
                      {assignment.Complete ? (
                        <button
                          className='status-button'
                          onClick={() => handleDownload(assignment.assignmentId)}
                          style={{ cursor: 'pointer' }}
                        >
                          Download
                        </button>
                      ) : (
                        <button className='status-button' disabled>
                          In Progress
                        </button>
                      )}

                    </div>
                  ))}
                </div>
              </>
            )}

          </>
        )}

        {activeMenu === 'notifications' && (
          <>
            <div className="dmnoticlass">
              <img src={dnotification} alt="" />
              <h1>No new notifications received</h1>
              <h2>Check again later for upcoming <br />updates</h2>
              <button className="dmnotigoback">Go Back</button>
            </div>
          </>
        )}

        {activeMenu === 'settings' && (
          <>
            <div className="dmsettingmenu">
              <h1>Welcome, {myname}!</h1>
              <h3>Membership Status: Active</h3>

              <div className="dmpropic">
                <div className="dmavatar">{myname.charAt(0).toUpperCase()}</div>
                <p>User Profile</p>
              </div>

              <p className='dmtextfullname'>Full Name</p>
              <input type="text" className="dminputfullname" placeholder='Kakashi Uzumaki' />

              <p className='dmtextemail'>Email</p>
              <p className='dmdisplayemail'>{userData && userData.email}</p>

              <button className="dmapplybutton">Apply</button>
            </div>
          </>
        )}



        <div className="dmmenubar"
          ref={menuurRef}
          style={{ opacity: 0, display: 'none' }}>

          <div className="dmtopthreeitems">
            <div className="dmnameandlogo">
              {/* display first name from the mongoDB */}
              <p>Hi, {myname}!</p>
              <img src={threedot} alt=""
                onClick={handleMobileMenu} />
            </div>

            <div className="dmdashboard"
              onClick={() => handleSectionClick('dashboard')}
              style={{ opacity: activeSection === 'dashboard' ? 1 : 0.7 }}>
              <img src={dashboardicon} alt="" />
              <p>Dashboard</p>
            </div>

            <div className="dmmytasks"
              onClick={() => handleSectionClick('mytasks')}
              style={{ opacity: activeSection === 'mytasks' ? 1 : 0.7 }}>
              <img src={mytaskicon} alt="" />
              <p>My Tasks</p>
            </div>

            <div className="dmnotifications"
              onClick={() => handleSectionClick('notifications')}
              style={{ opacity: activeSection === 'notifications' ? 1 : 0.7 }}>
              <img src={notificationicon} alt="" />
              <p>Notifications</p>
            </div>
          </div>

          <div className="dmbottomtwoitems">
            <div className="dmsettings"
              onClick={() => handleSectionClick('settings')}
              style={{ opacity: activeSection === 'settings' ? 1 : 0.7 }}>
              <img src={settingicon} alt="" />
              <p>Settings</p>
            </div>

            <div className="dmlogout"
              onClick={handleMobileLogout}
              style={{ opacity: activeSection === 'logout' ? 1 : 0.7 }} >
              <img src={logouticon} alt="" />
              <p>Logout</p>
            </div>
          </div>
        </div>

        <div className="dmlogoutmobilemenu"
          style={{ display: 'none' }}>
          <h1>Are you sure you want to logout?</h1>
          <div className="dmyesnobuttons">
            <button className="dmlogoutbutton" onClick={dlogoutyes}>Yes</button>
            <button className="dmcancelbutton" onClick={dlogoutno}>No</button>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Dashboard
