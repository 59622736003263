import React, { useState } from 'react';
import { useRef } from 'react';
import axios from 'axios';
import mysearch from '../images/mysearch.png';
import './adminstyle.css';

function AdminBoard() {
  const [assignmentId, setAssignmentId] = useState('');
  const [assignmentData, setAssignmentData] = useState(null);
  const myfilefetch = useRef(null);
  const [fetchfiles, setFetchfiles] = useState([]);
  const [error, setError] = useState('');

  const [aHaveFiles, setAHaveFiles] = useState(false);
  const [aFiles, setAFiles] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://yourgreatscore.com/api/assignments/${assignmentId}`);
      if (response.data) {
        setAssignmentData(response.data);
        setError('');
      } else {
        setAssignmentData(null);
        setError('No data exists');
      }
    } catch (error) {
      setAssignmentData(null);
      setError('No data exists');
    }
  };

  const handleFileuploadfetch = () => {
    myfilefetch.current.click();
  };
  const myfileschanges = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'zip' || fileExtension === 'rar') {
        setAFiles([file]);
        setAHaveFiles(true);
        setError('');
      } else {
        setAFiles([]);
        setAHaveFiles(false);
        setError('Please upload a valid .zip or .rar file.');
      }
    }
  };

  
  const handleFileUpload = async () => {
    if (!aHaveFiles || aFiles.length === 0) {
      setError('No file selected.');
      return;
    }
    
    if (!assignmentId) {
      setError('Assignment ID is not defined.');
      return;
    }
  
    const file = aFiles[0];
    const newFileName = `${assignmentId}_${file.name}`;
    const sanitizedFileName = newFileName.replace(/\s+/g, '_'); // Replace spaces with underscores
    const renamedFile = new File([file], sanitizedFileName, { type: file.type });
  
    const formData = new FormData();
    formData.append('file', renamedFile);
    formData.append('assignmentId', assignmentId);
  
    console.log('Assignment ID:', assignmentId);
    console.log('New File Name:', sanitizedFileName);
  
    try {
      const response = await axios.post('https://yourgreatscore.com/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true, // Include cookies if your backend requires authentication
      });
      alert(response.data); // "File uploaded successfully to S3"
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Error uploading file');
    }
  };
  

  return (
    <div className='aadminboard'>
      <div className="amyboard">
        <h1>Enter Assignment ID to Fetch The Data</h1>
        <h2>Assignment Id</h2>
        <div className="asearchbar">
          <input
            type="text"
            placeholder="Enter Assignment ID"
            value={assignmentId}
            onChange={(e) => setAssignmentId(e.target.value)}
          />
          <img src={mysearch} alt="" onClick={handleSearch} style={{ cursor: 'pointer' }} />
        </div>

        {/* display AssignmentTitle,Submission Date, For Expected Completion Time display 3 days for now and Completion*/}
        <div className="atable3">
          <table className='adisplaytable'>
            <thead>
              <tr>
                <th>Assignment Title</th>
                <th>Submission Date</th>
                <th>Expected Completion Time</th>
                <th>Complete</th> {/* Add the new column header */}
              </tr>
            </thead>
            <tbody>
              {assignmentData && (
                <tr>
                  <td>{assignmentData.assignmentTitle}</td>
                  <td>{assignmentData.submissionDate}</td>
                  <td>3 days</td>
                  <td style={{ color: assignmentData.Complete ? 'green' : 'red' }}>
                    {assignmentData.Complete ? 'Yes' : 'No'}
                  </td> {/* Display the "Complete" status */}
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* display this only if file exist or array length is greater than 0 */}
        {assignmentData && (
          <>
            <button className="dbrowsefile" onClick={handleFileuploadfetch} style={{ marginTop: '2rem' }}>
              Browse File
            </button>
            <input
              type="file"
              ref={myfilefetch}
              onChange={myfileschanges}
              style={{ display: 'none' }}
              className='dinputbrowsefile'
              accept=".zip,.rar"
              multiple={false}
            />

            {/* Display the aFiles array if it has files */}
            {aHaveFiles && (
              <>
                <div className="afiles">
                  {aFiles.map((file, index) => (
                    <div key={index} className="afile" style={{marginTop: '1rem'}}>
                      {file.name}
                    </div>
                  ))}
                </div>
                <p className='asurecomplete'>You want to Mark as Complete</p>
                <div className="atwobuttons">
                  <button className="ayes" onClick={handleFileUpload}>Yes</button>
                  <button className='ano'>No</button>
                </div>
              </>
            )}
          </>
        )}

        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
}

export default AdminBoard;
