//images
import mainlogo from './images/mainlogo.png';
import rectangleimg from './images/Rectangle.png';
import circleimg from './images/circle.png';
import cursorimg from './images/cursor.png';
import messageimg from './images/messageimg.png';
import senecalogo from './images/senecalogo.png';
import centenniallogo from './images/centenniallogo.png';
import humbercollege from './images/humbercollege.png';
import sheridanlogo from './images/sheridanlogo.png';
import georgelogo from './images/george.png';
import uoft from './images/uoft.png';
import mcmaster from './images/mcmaster.png';
import appinterface from './images/appinterface.png';
import secondbg from './images/secondbg.png';
import roundcut from './images/roundcut.png';
import screw from './images/screw.png';
import twitterlogo from './images/twitter.png';
import instagramlogo from './images/instagram.png';
import linkedinlogo from './images/linkedin.png';
import youtubelogo from './images/youtube.png';
import pinterest from './images/pinterest.png';

import { useLocation } from 'react-router-dom';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef, useEffect, useState } from "react";
import LocomotiveScroll from 'locomotive-scroll';
import { Draggable } from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
import { BrowserRouter as Router, Routes, Route, Link, BrowserRouter } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

//pages
import LoginPage from './components/loginpage';
import SignupPage from './components/signuppage';


gsap.registerPlugin(ScrollTrigger);


//pages


function App() {

  //variables
  const accountref = useRef();
  const arrow1 = useRef();
  const compnayref = useRef();
  const arrow2 = useRef();
  let arrow1Tween = useRef();
  const firstbutton = useRef();
  const logoref = useRef();
  const blackboxref = useRef();
  const pararef = useRef();
  const scrollRef = useRef(null);
  const scrollRefInstance = useRef(null);
  const readmore = useRef();
  const rightarrow = useRef();
  const draggableRef = useRef();
  const messageimgref = useRef();
  const accountdashboard = useRef();
  const bggrid1ref = useRef();
  const bggrid2ref = useRef();
  const bggrid3ref = useRef();
  const bggrid4ref = useRef();
  const policyref = useRef();
  const policygrid1 = useRef();
  const policygrid2 = useRef();
  const policygrid3 = useRef();
  const twitterref = useRef();
  const instagramref = useRef();
  const linkedinref = useRef();
  const youtuberef = useRef();
  const pinterestref = useRef();
  const pathwayh1 = useRef();
  const successh1 = useRef();
  const drawing1 = useRef();
  const loginbuttonref = useRef();
  const trustedref = useRef();
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayVisible2, setOverlayVisible2] = useState(false);
  const [overlayVisible3, setOverlayVisible3] = useState(false);
  const [overlayVisible4, setOverlayVisible4] = useState(false);
  const navigate = useNavigate();


  const location = useLocation(); // Add this line

  // Add this useEffect hook
  useEffect(() => {
    if (!window.gtagInitialized) {
      window.dataLayer = window.dataLayer || [];
      function gtag(){ window.dataLayer.push(arguments); }
      window.gtag = gtag;
      gtag('js', new Date());
  
      gtag('config', 'G-LJSX330ZJ9'); // Replace with your Measurement ID
      window.gtagInitialized = true;
    }
  }, []);

  // Track page views on route change
  useEffect(() => {
    // Send page view event to Google Analytics
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
        page_title: document.title,
      });
    }
  }, [location]);


  useGSAP(() => {
    const tl = gsap.timeline();
    const h1pathway = new SplitType('.pathwayclass');
    const h1success = new SplitType('.successclass');


    gsap.to('.char', {
      y: 0,
      stagger: 0.02,
      delay: 0.2,
      duration: .1
    })

    tl.from(accountref.current, {
      opacity: 0,
      duration: 0.5,
      x: 100,
    })

    tl.from(arrow1.current, {
      opacity: 0,
      duration: 0.5,
      x: 100,
    }, '-=0.47')

    tl.from(compnayref.current, {
      opacity: 0,
      duration: 0.5,
      x: 100,
    }, '-=0.5')

    tl.from(arrow2.current, {
      opacity: 0,
      duration: 0.5,
      x: 100,
    }, '-=0.47')

    tl.from(firstbutton.current, {
      opacity: 0,
      duration: 0.5,
      x: 100,
    }, '-=0.5')

    tl.from(logoref.current, {
      opacity: 0,
      duration: 0.5,
      x: -100,
    }, '-=0.5')

    tl.from(blackboxref.current, {
      opacity: 0,
      duration: 0.5,
    }, '-=0.15')

    tl.from(pararef.current, {
      opacity: 0.5,
      duration: 0.2,
    }, '-=0.5')

    tl.from(draggableRef.current, {
      opacity: 0,
      duration: 0.5,
      x: -100,
    }, '-=0.5')

    tl.from(messageimgref.current, {
      opacity: 0,
      duration: 0.5,
      x: 100,
    }, '-=0.5')

    tl.from(loginbuttonref.current, {
      opacity: 0,
      duration: 0.5,
      y: 80,
    }, '-=0.5')



    // when click on accountref show accountdashboard and turn accountref color to white and also arrow1 to white and when click again hide accountdashboard and turn accountref color to grey and also arrow1 to grey
    // accountref.current.addEventListener('click', () => {
    //   if (accountdashboard.current.style.visibility === 'visible') {
    //     gsap.to(arrow1.current, {
    //       borderBottom: '2px solid grey',
    //       borderRight: '2px solid grey',
    //       rotate: 45,
    //       duration: 0.3
    //     })
    //     gsap.to(accountdashboard.current, {
    //       visibility: 'hidden',
    //       duration: 0.3
    //     })
    //     gsap.to(accountref.current, {
    //       color: 'grey',
    //       duration: 0.3
    //     })
    //   } else {
    //     gsap.to(arrow1.current, {
    //       borderBottom: '2px solid white',
    //       borderRight: '2px solid white',
    //       rotate: -135,
    //       duration: 0.3
    //     })
    //     gsap.to(accountdashboard.current, {
    //       visibility: 'visible',
    //       duration: 0.3
    //     })
    //     gsap.to(accountref.current, {
    //       color: 'white',
    //       duration: 0.3
    //     })
    //   }
    // })

    // when click outside accountdashboard hide accountdashboard and turn accountref color to grey and also arrow1 to grey
    // document.addEventListener('click', (e) => {
    //   if (e.target !== accountref.current && e.target !== accountdashboard.current) {
    //     gsap.to(arrow1.current, {
    //       borderBottom: '2px solid grey',
    //       borderRight: '2px solid grey',
    //       rotate: 45,
    //       duration: 0.3
    //     })
    //     gsap.to(accountdashboard.current, {
    //       visibility: 'hidden',
    //       duration: 0.3
    //     })
    //     gsap.to(accountref.current, {
    //       color: 'grey',
    //       duration: 0.3
    //     })
    //   }
    // })

    //try hover
    // Define the event handler functions at the top of your useGSAP function
    const handleAccountRefMouseEnter = () => {
      gsap.to(arrow1.current, {
        borderBottom: '2px solid white',
        borderRight: '2px solid white',
        rotate: -135,
        duration: 0.3
      });
      gsap.to(accountdashboard.current, {
        visibility: 'visible',
        duration: 0.3
      });
      gsap.to(accountref.current, {
        color: 'white',
        duration: 0.3
      });
    };

    const handleAccountRefMouseLeave = (e) => {
      // Check if the mouse is moving into accountdashboard
      if (!accountdashboard.current.contains(e.relatedTarget)) {
        gsap.to(arrow1.current, {
          borderBottom: '2px solid grey',
          borderRight: '2px solid grey',
          rotate: 45,
          duration: 0.3
        });
        gsap.to(accountdashboard.current, {
          visibility: 'hidden',
          duration: 0.3
        });
        gsap.to(accountref.current, {
          color: 'grey',
          duration: 0.3
        });
      }
    };

    const handleAccountDashboardMouseLeave = (e) => {
      // Check if the mouse is leaving to an element that's not accountref
      if (!accountref.current.contains(e.relatedTarget)) {
        gsap.to(arrow1.current, {
          borderBottom: '2px solid grey',
          borderRight: '2px solid grey',
          rotate: 45,
          duration: 0.3
        });
        gsap.to(accountdashboard.current, {
          visibility: 'hidden',
          duration: 0.3
        });
        gsap.to(accountref.current, {
          color: 'grey',
          duration: 0.3
        });
      }
    };

    // Add these inside your useGSAP function, after defining the event handlers
    accountref.current.addEventListener('mouseenter', handleAccountRefMouseEnter);
    accountref.current.addEventListener('mouseleave', handleAccountRefMouseLeave);
    accountdashboard.current.addEventListener('mouseleave', handleAccountDashboardMouseLeave);



    // when click on compnayref show policyref and turn compnayref color to white and also arrow2 to white and when click again hide policyref and turn compnayref color to grey and also arrow2 to grey
    // compnayref.current.addEventListener('click', () => {
    //   if (policyref.current.style.visibility === 'visible') {
    //     gsap.to(arrow2.current, {
    //       borderBottom: '2px solid grey',
    //       borderRight: '2px solid grey',
    //       rotate: 45,
    //       duration: 0.3
    //     })
    //     gsap.to(policyref.current, {
    //       visibility: 'hidden',
    //       duration: 0.3
    //     })
    //     gsap.to(compnayref.current, {
    //       color: 'grey',
    //       duration: 0.3
    //     })
    //   } else {
    //     gsap.to(arrow2.current, {
    //       borderBottom: '2px solid white',
    //       borderRight: '2px solid white',
    //       rotate: -135,
    //       duration: 0.3
    //     })
    //     gsap.to(policyref.current, {
    //       visibility: 'visible',
    //       duration: 0.3
    //     })
    //     gsap.to(compnayref.current, {
    //       color: 'white',
    //       duration: 0.3
    //     })
    //   }
    // })

    // when click outside policyref hide policyref and turn compnayref color to grey and also arrow2 to grey
    // document.addEventListener('click', (e) => {
    //   if (e.target !== compnayref.current && e.target !== policyref.current) {
    //     gsap.to(arrow2.current, {
    //       borderBottom: '2px solid grey',
    //       borderRight: '2px solid grey',
    //       rotate: 45,
    //       duration: 0.3
    //     })
    //     gsap.to(policyref.current, {
    //       visibility: 'hidden',
    //       duration: 0.3
    //     })
    //     gsap.to(compnayref.current, {
    //       color: 'grey',
    //       duration: 0.3
    //     })
    //   }
    // })

    //try hover
    // Define the event handler functions for Policy
    const handleCompnayRefMouseEnter = () => {
      gsap.to(arrow2.current, {
        borderBottom: '2px solid white',
        borderRight: '2px solid white',
        rotate: -135,
        duration: 0.3,
      });
      gsap.to(policyref.current, {
        visibility: 'visible',
        duration: 0.3,
      });
      gsap.to(compnayref.current, {
        color: 'white',
        duration: 0.3,
      });
    };

    const handleCompnayRefMouseLeave = (e) => {
      // Check if the mouse is moving into policyref
      if (!policyref.current.contains(e.relatedTarget)) {
        gsap.to(arrow2.current, {
          borderBottom: '2px solid grey',
          borderRight: '2px solid grey',
          rotate: 45,
          duration: 0.3,
        });
        gsap.to(policyref.current, {
          visibility: 'hidden',
          duration: 0.3,
        });
        gsap.to(compnayref.current, {
          color: 'grey',
          duration: 0.3,
        });
      }
    };

    const handlePolicyRefMouseLeave = (e) => {
      // Check if the mouse is leaving to an element that's not compnayref
      if (!compnayref.current.contains(e.relatedTarget)) {
        gsap.to(arrow2.current, {
          borderBottom: '2px solid grey',
          borderRight: '2px solid grey',
          rotate: 45,
          duration: 0.3,
        });
        gsap.to(policyref.current, {
          visibility: 'hidden',
          duration: 0.3,
        });
        gsap.to(compnayref.current, {
          color: 'grey',
          duration: 0.3,
        });
      }
    };


    // Add these inside your useGSAP function, after defining the event handlers
    compnayref.current.addEventListener('mouseenter', handleCompnayRefMouseEnter);
    compnayref.current.addEventListener('mouseleave', handleCompnayRefMouseLeave);
    policyref.current.addEventListener('mouseleave', handlePolicyRefMouseLeave);


    //for readmore
    readmore.current.addEventListener('mouseenter', () => {
      gsap.to(readmore.current, {
        opacity: 0.3,
        duration: 0.2
      })
    })

    readmore.current.addEventListener('mouseout', () => {
      gsap.to(readmore.current, {
        opacity: 1,
        duration: 0.2
      })
    })

    //for rightArrow
    readmore.current.addEventListener('mouseenter', () => {
      gsap.to(rightarrow.current, {
        opacity: 0.3,
        x: 1,
        duration: 0.2
      })
    })

    readmore.current.addEventListener('mouseout', () => {
      gsap.to(rightarrow.current, {
        opacity: 1,
        x: 0,
        duration: 0.2
      })
    })

    rightarrow.current.addEventListener('mouseenter', () => {
      gsap.to(rightarrow.current, {
        opacity: 0.3,
        x: 1,
        duration: 0.2
      })
    })

    rightarrow.current.addEventListener('mouseout', () => {
      gsap.to(rightarrow.current, {
        opacity: 1,
        x: 0,
        duration: 0.2
      })
    })

    const draggable = Draggable.create(draggableRef.current, {
      //whenever the element is dragged make it draggable and also come back when it is released for x and y both
      type: "x,y",
      edgeResistance: 0.65,
      bounds: ".myapp",

      onRelease: function () {
        gsap.to(draggableRef.current, {
          x: 0,
          y: 0,
          duration: 0.5,
          // ease: "elastic.out(1, 0.3)"
        })
      }
    })

    const messageimg = Draggable.create(messageimgref.current, {
      //whenever the element is dragged make it draggable and also come back when it is released for x and y both
      type: "x,y",
      edgeResistance: 0.65,
      bounds: ".myapp",

      onRelease: function () {
        gsap.to(messageimgref.current, {
          x: 0,
          y: 0,
          duration: 0.5,
          // ease: "elastic.out(1, 0.3)"
        })
      }
    })

    const bggrid1 = bggrid1ref.current;
    bggrid1.addEventListener('mouseenter', () => {
      gsap.to(bggrid1, {
        borderRadius: '10px',
        backgroundColor: 'rgba(128,128,128,0.2)',
        duration: 0.2,
        cursor: 'pointer',
      });
    });
    bggrid1.addEventListener('mouseleave', () => {
      gsap.to(bggrid1, {
        backgroundColor: 'initial', // Change back to initial color
        duration: 0.7,
      });
    });
    Array.from(bggrid1.children).forEach(child => {
      child.style.pointerEvents = 'none';
    });

    const bggrid2 = bggrid2ref.current;
    bggrid2.addEventListener('mouseenter', () => {
      gsap.to(bggrid2, {
        borderRadius: '10px',
        backgroundColor: 'rgba(128,128,128,0.2)',
        duration: 0.3,
        cursor: 'pointer',
      });
    });
    bggrid2.addEventListener('mouseleave', () => {
      gsap.to(bggrid2, {
        backgroundColor: 'initial', // Change back to initial color
        duration: 0.7,
      });
    });
    Array.from(bggrid2.children).forEach(child => {
      child.style.pointerEvents = 'none';
    });

    const bggrid3 = bggrid3ref.current;
    bggrid3.addEventListener('mouseenter', () => {
      gsap.to(bggrid3, {
        borderRadius: '10px',
        backgroundColor: 'rgba(128,128,128,0.2)',
        duration: 0.3,
        cursor: 'pointer',
      });
    });
    bggrid3.addEventListener('mouseleave', () => {
      gsap.to(bggrid3, {
        backgroundColor: 'initial', // Change back to initial color
        duration: 0.7,
      });
    });
    Array.from(bggrid3.children).forEach(child => {
      child.style.pointerEvents = 'none';
    });

    const bggrid4 = bggrid4ref.current;
    bggrid4.addEventListener('mouseenter', () => {
      gsap.to(bggrid4, {
        borderRadius: '10px',
        backgroundColor: 'rgba(128,128,128,0.2)',
        duration: 0.3,
        cursor: 'pointer',
      });
    });
    bggrid4.addEventListener('mouseleave', () => {
      gsap.to(bggrid4, {
        backgroundColor: 'initial', // Change back to initial color
        duration: 0.7,
      });
    });
    Array.from(bggrid4.children).forEach(child => {
      child.style.pointerEvents = 'none';
    });

    const poligrid1 = policygrid1.current;
    poligrid1.addEventListener('mouseenter', () => {
      gsap.to(poligrid1, {
        borderRadius: '10px',
        backgroundColor: 'rgba(128,128,128,0.2)',
        duration: 0.3,
        cursor: 'pointer',
      });
    });
    poligrid1.addEventListener('mouseleave', () => {
      gsap.to(poligrid1, {
        backgroundColor: 'initial', // Change back to initial color
        duration: 0.7,
      });
    });
    Array.from(poligrid1.children).forEach(child => {
      child.style.pointerEvents = 'none';
    });

    const poligrid2 = policygrid2.current;
    poligrid2.addEventListener('mouseenter', () => {
      gsap.to(poligrid2, {
        borderRadius: '10px',
        backgroundColor: 'rgba(128,128,128,0.2)',
        duration: 0.3,
        cursor: 'pointer',
      });
    });
    poligrid2.addEventListener('mouseleave', () => {
      gsap.to(poligrid2, {
        backgroundColor: 'initial', // Change back to initial color
        duration: 0.7,
      });
    });
    Array.from(poligrid2.children).forEach(child => {
      child.style.pointerEvents = 'none';
    });

    const poligrid3 = policygrid3.current;
    poligrid3.addEventListener('mouseenter', () => {
      gsap.to(poligrid3, {
        borderRadius: '10px',
        backgroundColor: 'rgba(128,128,128,0.2)',
        duration: 0.3,
        cursor: 'pointer',
      });
    });
    poligrid3.addEventListener('mouseleave', () => {
      gsap.to(poligrid3, {
        backgroundColor: 'initial', // Change back to initial color
        duration: 0.7,
      });
    });
    Array.from(poligrid3.children).forEach(child => {
      child.style.pointerEvents = 'none';
    });


    //logo hover
    twitterref.current.addEventListener('mouseenter', () => {
      gsap.to(twitterref.current, {
        duration: 0.2,
        opacity: 0.5,
      })
    })

    twitterref.current.addEventListener('mouseleave', () => {
      gsap.to(twitterref.current, {
        opacity: 1,
      })
    })

    instagramref.current.addEventListener('mouseenter', () => {
      gsap.to(instagramref.current, {
        duration: 0.2,
        opacity: 0.5,
      })
    })

    instagramref.current.addEventListener('mouseleave', () => {
      gsap.to(instagramref.current, {
        opacity: 1,
      })
    })

    linkedinref.current.addEventListener('mouseenter', () => {
      gsap.to(linkedinref.current, {
        duration: 0.2,
        opacity: 0.5,
      })
    })

    linkedinref.current.addEventListener('mouseleave', () => {
      gsap.to(linkedinref.current, {
        opacity: 1,
      })
    })

    youtuberef.current.addEventListener('mouseenter', () => {
      gsap.to(youtuberef.current, {
        duration: 0.2,
        opacity: 0.5,
      })
    })

    youtuberef.current.addEventListener('mouseleave', () => {
      gsap.to(youtuberef.current, {
        opacity: 1,
      })
    })

    pinterestref.current.addEventListener('mouseenter', () => {
      gsap.to(pinterestref.current, {
        duration: 0.2,
        opacity: 0.5,
      })
    })

    pinterestref.current.addEventListener('mouseleave', () => {
      gsap.to(pinterestref.current, {
        opacity: 1,
      })
    })


    return () => {
      // Remove the event listeners
      accountref.current.removeEventListener('mouseenter', handleAccountRefMouseEnter);
      accountref.current.removeEventListener('mouseleave', handleAccountRefMouseLeave);
      accountdashboard.current.removeEventListener('mouseleave', handleAccountDashboardMouseLeave);

      compnayref.current.removeEventListener('mouseenter', handleCompnayRefMouseEnter);
      compnayref.current.removeEventListener('mouseleave', handleCompnayRefMouseLeave);
      policyref.current.removeEventListener('mouseleave', handlePolicyRefMouseLeave);

      // Existing cleanup code for draggable instances
      draggable[0].kill();
      messageimg[0].kill();
    };





  });

  const isAuthenticated = () => {
    return localStorage.getItem('username') !== null;
  };

  // comment below code if you don't want to redirect to dashboard if user is already logged in
  // useEffect(() => {
  //   if (isAuthenticated()) {
  //     navigate('/dashboard');
  //   }
  // }, []);
  // comment above code if you don't want to redirect to dashboard if user is already logged in

  const [welcometext, setWelcomeText] = useState('Welcome to GreatScore Academy, where we believe that every student deserves the opportunity to excel academically and beyond.');
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 359 && window.innerWidth <= 800) {
        setWelcomeText("Welcome to GreatScore, where we believe that every student deserves the opportunity to excel academically");
      }
      // else if (window.innerWidth >= 410 && window.innerWidth <= 431) {
      //   setWelcomeText("Welcome to GreatScore Academy, where we believe that every student deserves the opportunity to excel academically and beyond.");
      // }
      else {
        setWelcomeText("Welcome to GreatScore Academy, where we believe that every student deserves the opportunity to excel academically and beyond.");
      }



    }

    window.addEventListener('resize', handleResize);

    // Call the function initially
    handleResize();

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRefInstance.current = new LocomotiveScroll({
        el: scrollRef.current,
        smooth: true,
        // Other Locomotive Scroll options here
      });
    }

    // Cleanup function to destroy Locomotive Scroll when the component unmounts
    return () => {
      if (scrollRefInstance.current) {
        scrollRefInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (overlayVisible4) {
      // Disable Locomotive Scroll
      if (scrollRefInstance.current) {
        scrollRefInstance.current.stop();
      }
      // Optionally, disable scrolling on the body (if needed)
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable Locomotive Scroll
      if (scrollRefInstance.current) {
        scrollRefInstance.current.start();
      }
      // Re-enable scrolling on the body
      document.body.style.overflow = '';
    }
  }, [overlayVisible4]);


  /* Handle click on the Policy grid item */

  const handleClick = () => {
    setOverlayVisible(true);
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
  };

  const handleClick2 = () => {
    setOverlayVisible2(true);
  };

  const closeOverlay2 = () => {
    setOverlayVisible2(false);
  };

  const handleClick3 = () => {
    setOverlayVisible3(true);
  };

  const closeOverlay3 = () => {
    setOverlayVisible3(false);
  };

  const handleClick4 = () => {
    setOverlayVisible4(true);
  };

  const closeOverlay4 = () => {
    setOverlayVisible4(false);
  }

  /* Handle click on the Policy grid item End */

  //onclicks
  const handlebggrid1click = () => {
    navigate('/api/login');
  }

  const handlebggrid2click = () => {
    navigate('/signup');
  }

  const handlebggrid3click = () => {
    navigate('/dashboard');
  }

  const handlebggrid4click = () => {
    navigate('/dashboard');
  }


  // Account - Login,Signup,Dashboard,profile
  // Policy - About us, Privacy Policy, Terms of Service



  return (

    <AnimatePresence mode='wait'>
      <div className="myapp" ref={scrollRef}>

        <div className="navbar">
          <img src={mainlogo} alt="GreatScore Logo" ref={logoref} />
          <div className="rightnav">
            <h4 ref={accountref} className='account'>Account</h4>
            <i class="arrow1" ref={arrow1}></i>
            <h4 ref={compnayref} className='policy'>Policy</h4>
            <i class="arrow2" ref={arrow2}></i>
            <button ref={firstbutton}>
              <Link to="/signup" style={{ textDecoration: 'none', color: 'black' }}>Get Started</Link>
            </button>
          </div>
        </div>

        <div className="accountdashboard" ref={accountdashboard}>

          <div className="bggrid1" ref={bggrid1ref} onClick={handlebggrid1click}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(128,128,128,1)"><path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V13.5L23 18L18 22.5V19H15V17H18Z"></path></svg>
            <p>Login</p>
          </div>

          <div className="bggrid2" ref={bggrid2ref} onClick={handlebggrid2click}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(128,128,128,1)"><path d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM7 6H11V10H7V6ZM7 12H17V14H7V12ZM7 16H17V18H7V16ZM13 7H17V9H13V7Z"></path></svg>
            <p>Sign Up</p>
          </div>

          <div className="bggrid3" ref={bggrid3ref} onClick={handlebggrid3click}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(128,128,128,1)"><path d="M5 3V19H21V21H3V3H5ZM20.2929 6.29289L21.7071 7.70711L16 13.4142L13 10.415L8.70711 14.7071L7.29289 13.2929L13 7.58579L16 10.585L20.2929 6.29289Z"></path></svg>
            <p>Dashboard</p>
          </div>

          <div className="bggrid4" ref={bggrid4ref} onClick={handlebggrid4click}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(128,128,128,1)"><path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V14H20V17H23V19H20V22H18V19H15V17H18Z"></path></svg>
            <p>Profile</p>
          </div>

        </div>

        {/* handle click on the Policy grid item */}

        {overlayVisible && (
          <div className="overlay">
            <div className="overlay-content">
              <div style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
                <h2>About Us</h2>
                <p><br></br>Welcome to GreatScore, your trusted partner in academic success. Founded on the principle of providing comprehensive and accessible academic support, we are dedicated to empowering students to achieve their fullest potential.<br></br><br></br>

                  At GreatScore, we understand the challenges and pressures that students face in today's academic environment. From demanding assignments to rigorous deadlines, navigating through the complexities of education can be overwhelming. That's where we come in. With a team of experienced professionals and experts in various fields, we offer a range of services designed to alleviate the burdens of academic life and facilitate success.<br></br><br></br>

                  Our mission is simple: to provide high-quality, affordable, and personalized assistance to students at every level of their academic journey. Whether you're struggling with a difficult assignment, seeking guidance on a complex project, or simply looking for additional resources to enhance your learning experience, we're here to help.<br></br><br></br>

                  What sets us apart is our commitment to excellence and customer satisfaction. We take pride in delivering top-notch assistance tailored to the unique needs of each student. From the moment you reach out to us, you can expect prompt and professional service, clear communication, and unwavering support every step of the way.<br></br><br></br>

                  Transparency and integrity are at the core of everything we do. We believe in building trust and fostering long-term relationships with our clients based on honesty, reliability, and mutual respect. When you choose GreatScore, you can rest assured that you're in good hands.<br></br><br></br>

                  In addition to our commitment to academic excellence, we are also dedicated to making a positive impact in the community. Through partnerships with educational institutions, charitable organizations, and community initiatives, we strive to give back and contribute to the greater good.<br></br><br></br>

                  As we continue to grow and evolve, our focus remains steadfast on our core values of quality, affordability, and customer satisfaction. Whether you're a high school student struggling with homework, a college student facing a daunting research paper, or a working professional seeking to expand your knowledge, GreatScore is here to support you on your journey to success.<br></br><br></br>

                  Thank you for considering us as your partner in academic achievement. We look forward to helping you reach your goals and surpassing your expectations.</p>
              </div>
              <span className="close-btn" onClick={closeOverlay}>&times;</span>
            </div>
          </div>
        )}

        {overlayVisible2 && (
          <div className="overlay">
            <div className="overlay-content">
              <div style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
                <h2>Privacy Policy</h2>
                <p>
                  Effective Date: April 4 2024<br></br><br></br>

                  Welcome to GreateScore Inc. We are committed to protecting your privacy and ensuring that your personal information is handled responsibly. This Privacy Policy explains how we collect, use, protect, and disclose information and data when you use our website www.greatscore.ca.<br></br><br></br>

                  1. Information Collection<br></br><br></br>

                  We collect information that you provide directly to us when you:<br></br><br></br>

                  Register for an account<br></br>
                  Request services<br></br>
                  Participate in our interactive features, surveys, contests, promotions, or webinars<br></br>
                  Communicate with us via customer support channels<br></br>
                  This information may include your name, email address, phone number, educational background, and payment information.<br></br><br></br>

                  2. Information Use<br></br><br></br>

                  We use the information we collect to:<br></br><br></br>

                  Provide, maintain, and improve our services<br></br>
                  Process transactions and send notices about your transactions<br></br>
                  Communicate with you about products, services, offers, promotions, and events<br></br>
                  Respond to your inquiries and provide customer service<br></br>
                  Monitor and analyze trends, usage, and activities<br></br><br></br>

                  3. Information Sharing<br></br><br></br>

                  We may share your information with:<br></br><br></br>

                  Service providers who perform services on our behalf, such as payment processing and data analysis<br></br>
                  Our partners in educational services if you have requested services or information that they provide<br></br>
                  Law enforcement, government officials, or other third parties if required by law<br></br><br></br>
                  4. Data Security<br></br><br></br>

                  We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction.<br></br><br></br>

                  5. Your Rights<br></br><br></br>

                  You have the right to:<br></br><br></br>

                  Access and update your personal information via your account settings<br></br>
                  Opt out of receiving promotional communications by following the unsubscribe instructions provided<br></br>
                  Request deletion of your personal information<br></br><br></br>
                  6. Cookies and Tracking Technologies<br></br><br></br>

                  We use various technologies to collect information, including cookies and web beacons. These help us understand your behavior, tell us which parts of our website people have visited, and facilitate and measure the effectiveness of advertisements and web searches.<br></br><br></br>

                  7. Changes to This Privacy Policy<br></br><br></br>

                  We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, providing you with additional notice.<br></br><br></br>

                  8. Contact Us<br></br><br></br>

                  If you have any questions about this Privacy Policy, please contact us at [Provide Contact Information]
                </p>
              </div>
              <span className="close-btn" onClick={closeOverlay2}>&times;</span>
            </div>
          </div>
        )}

        {overlayVisible3 && (
          <div className="overlay">
            <div className="overlay-content">
              <div style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
                <h2>FAQs</h2>
                <p>
                  Some Commonly Asked Questions<br></br><br></br>

                  1. What exactly is GreatScore?<br></br><br></br>
                  GreatScore is a professional service where we assist students by completing their assignments for a fee. We ensure that your academic needs are met efficiently and effectively.<br></br><br></br>

                  2. How much does GreatScore charge per assignment?<br></br><br></br>
                  The cost per assignment typically depends on the complexity and requirements of the work. Generally, our charges start at approximately $15 per assignment.<br></br><br></br>

                  3. How long does it take to complete an assignment?<br></br><br></br>
                  We aim to complete all assignments within 2-3 days after they have been uploaded to our platform. Our process is designed to meet tight deadlines while maintaining quality.<br></br><br></br>

                  4. What happens if you are unable to complete my assignment on time?<br></br><br></br>
                  Although it is a rare occurrence, if we are unable to meet the deadline for your assignment, we will issue a full refund. We are committed to ensuring satisfaction and reliability.<br></br><br></br>

                  5. How do I pay for my assignment?<br></br><br></br>
                  Payments can be made through secure platform using interac. Additional payment options like (Credit,Debit Card) are currently being developed and will be available soon.<br></br>
                </p>
              </div>
              <span className="close-btn" onClick={closeOverlay3}>&times;</span>
            </div>
          </div>
        )}

        {overlayVisible4 && (
          <div className="overlay" onClick={closeOverlay4}>
            <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
              <div style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
                <h2>About Us</h2>
                <p><br></br>Welcome to GreatScore, your trusted partner in academic success. Founded on the principle of providing comprehensive and accessible academic support, we are dedicated to empowering students to achieve their fullest potential.<br></br><br></br>

                  At GreatScore, we understand the challenges and pressures that students face in today's academic environment. From demanding assignments to rigorous deadlines, navigating through the complexities of education can be overwhelming. That's where we come in. With a team of experienced professionals and experts in various fields, we offer a range of services designed to alleviate the burdens of academic life and facilitate success.<br></br><br></br>

                  Our mission is simple: to provide high-quality, affordable, and personalized assistance to students at every level of their academic journey. Whether you're struggling with a difficult assignment, seeking guidance on a complex project, or simply looking for additional resources to enhance your learning experience, we're here to help.<br></br><br></br>

                  What sets us apart is our commitment to excellence and customer satisfaction. We take pride in delivering top-notch assistance tailored to the unique needs of each student. From the moment you reach out to us, you can expect prompt and professional service, clear communication, and unwavering support every step of the way.<br></br><br></br>

                  Transparency and integrity are at the core of everything we do. We believe in building trust and fostering long-term relationships with our clients based on honesty, reliability, and mutual respect. When you choose GreatScore, you can rest assured that you're in good hands.<br></br><br></br>

                  In addition to our commitment to academic excellence, we are also dedicated to making a positive impact in the community. Through partnerships with educational institutions, charitable organizations, and community initiatives, we strive to give back and contribute to the greater good.<br></br><br></br>

                  As we continue to grow and evolve, our focus remains steadfast on our core values of quality, affordability, and customer satisfaction. Whether you're a high school student struggling with homework, a college student facing a daunting research paper, or a working professional seeking to expand your knowledge, GreatScore is here to support you on your journey to success.<br></br><br></br>

                  Thank you for considering us as your partner in academic achievement. We look forward to helping you reach your goals and surpassing your expectations.</p>
              </div>
              <span className="close-btn" onClick={closeOverlay4}>&times;</span>
            </div>
          </div>
        )}

        {/* handle click on the Policy grid item End */}

        <div className="policydashboard" ref={policyref}>

          <div className="polic1" ref={policygrid1} onClick={handleClick}>
            <svg className='mysvg1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(128,128,128,1)"><path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path></svg>
            <p className='mypara1'>About Us</p>
          </div>

          <div className="polic2" ref={policygrid2} onClick={handleClick2}>
            <svg className='mysvg2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(128,128,128,1)"><path d="M4 2C3.44772 2 3 2.44772 3 3V5H5V4H19V20H5V19H3V21C3 21.5523 3.44772 22 4 22H20C20.5523 22 21 21.5523 21 21V3C21 2.44772 20.5523 2 20 2H4ZM9 16C9 14.3431 10.3431 13 12 13C13.6569 13 15 14.3431 15 16H9ZM12 12C10.8954 12 10 11.1046 10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12ZM6 9V7H2V9H6ZM6 11V13H2V11H6ZM6 17V15H2V17H6Z"></path></svg>
            <p className='mypara2'>Privacy Policy</p>
          </div>

          <div className="polic3" ref={policygrid3} onClick={handleClick3}>
            <svg className='mysvg3' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(128,128,128,1)"><path d="M5.45455 15L1 18.5V3C1 2.44772 1.44772 2 2 2H17C17.5523 2 18 2.44772 18 3V15H5.45455ZM4.76282 13H16V4H3V14.3851L4.76282 13ZM8 17H18.2372L20 18.3851V8H21C21.5523 8 22 8.44772 22 9V22.5L17.5455 19H9C8.44772 19 8 18.5523 8 18V17Z"></path></svg>
            <p className='mypara3'>FAQs</p>
          </div>
        </div>



        <button className='loginbutton' ref={loginbuttonref}>
          <Link to="/signup" style={{ textDecoration: 'none', color: 'black' }}>Get Started</Link>
        </button>

        <div className="testcontainer">
          <div className="testrect"></div>
          <div className="circleoutline"></div>
          <div className="circlecss"></div>
        </div>

        <div className="mygrid">
          <div className="blackbox" ref={blackboxref}>
            <p>Assignments done.</p>
            <p className='readmore' ref={readmore} onClick={handleClick4}> Read more</p>
            <svg ref={rightarrow} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path></svg>
          </div>
          <h1 ref={pathwayh1} className='pathwayclass'>Your Pathway</h1>
          <img src={cursorimg} alt="" className='cursorimg' ref={draggableRef} />
          <img src={messageimg} alt="" className='messageimg' ref={messageimgref} />
          <h1 ref={successh1} className='successclass'>To Success</h1>
          <p className='welcomegreat' ref={pararef}>{welcometext}</p>

        </div>

        <div className="logogrid">
          <p className='trusted' ref={trustedref}>Trusted by students from Universities and Colleges all over Canada</p>
          <div className="mylogos">
            <img src={senecalogo} alt="" />
            <img src={humbercollege} alt="" />
            <img src={sheridanlogo} alt="" />
            <img src={georgelogo} alt="" />
            <img src={uoft} alt="" />
            <img src={mcmaster} alt="" />
          </div>
        </div>

        <div className="seconddescription">
          <h1>Everything you need</h1>
          <p className='empowerp'>we'll empower you to overcome obstacles, unlock your potential, and reach new heights of academic success.</p>
          <div className="threemains">

            <div className="boxx1">
              <div className="icon1">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M223.45,40.07a8,8,0,0,0-7.52-7.52C139.8,28.08,78.82,51,52.82,94a87.09,87.09,0,0,0-12.76,49c.57,15.92,5.21,32,13.79,47.85l-19.51,19.5a8,8,0,0,0,11.32,11.32l19.5-19.51C81,210.73,97.09,215.37,113,215.94q1.67.06,3.33.06A86.93,86.93,0,0,0,162,203.18C205,177.18,227.93,116.21,223.45,40.07ZM153.75,189.5c-22.75,13.78-49.68,14-76.71.77l88.63-88.62a8,8,0,0,0-11.32-11.32L65.73,179c-13.19-27-13-54,.77-76.71,22.09-36.47,74.6-56.44,141.31-54.06C210.2,114.89,190.22,167.41,153.75,189.5Z"></path></svg>
              </div>
              <h6>Easy Assignment Done</h6>
              <p>Complete your assignment affordably and effortlessly with our assistance, Get started now.</p>
            </div>

            <div className="boxx2">
              <div className="icon2">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M243.31,136,144,36.69A15.86,15.86,0,0,0,132.69,32H40a8,8,0,0,0-8,8v92.69A15.86,15.86,0,0,0,36.69,144L136,243.31a16,16,0,0,0,22.63,0l84.68-84.68a16,16,0,0,0,0-22.63Zm-96,96L48,132.69V48h84.69L232,147.31ZM96,84A12,12,0,1,1,84,72,12,12,0,0,1,96,84Z"></path></svg>
              </div>
              <h6>Assignment Affordability</h6>
              <p>Affordable assignmens offering premium quality at budget-friendly prices, enabling you to excel in your academic journey.</p>
            </div>


            <div className="boxx3">
              <div className="icon3">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M128,40a96,96,0,1,0,96,96A96.11,96.11,0,0,0,128,40Zm0,176a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,216ZM173.66,90.34a8,8,0,0,1,0,11.32l-40,40a8,8,0,0,1-11.32-11.32l40-40A8,8,0,0,1,173.66,90.34ZM96,16a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,16Z"></path></svg>
              </div>
              <h6>Early Submission</h6>
              <p>Ensure early submission for better results. Complete your assignment ahead of schedule and achieve a GreatScore.</p>
            </div>
          </div>
        </div>

        <div className="mysecondbackground">
          <img src={secondbg} alt="" className='secondbg' />
        </div>

        <div className="thirddescription">
          <h1>Intuitive Interface</h1>
          <p>Streamlined interface to Upload assignments, pay securely, and receive completed work promptly. Simplify your academic journey with GreatScore.</p>
          <img src={appinterface} alt="" className='appinterface' />
        </div>

        <div className="thirdreview">
          <h1>What Our Users Say</h1>

          <div className="userreview">

            <div className="card1">
              <p className='descrip'>Exceptional service! I needed urgent help with my assignment and was impressed by their prompt response and affordable prices. Highly recommended!</p>
              <div className="roundphoto"></div>
              <p className='fname'>Chelsi Neil</p>
              <p className='uname'>@ChelsiNeil32</p>
            </div>

            <div className="card2">
              <p className='descrip'>Fantastic experience! The service was efficient and the price was very reasonable. I received top-notch quality work. Definitely worth it!</p>
              <div className="roundphoto"></div>
              <p className='fname'>Sofia Kein</p>
              <p className='uname'>@Sofiakein1</p>
            </div>

            <div className="card3">
              <p className='descrip'>Outstanding service! I was delighted with the affordability and quality of my assignment. </p>
              <div className="roundphoto"></div>
              <p className='fname'>Jimi Shah</p>
              <p className='uname'>@JimiSh34</p>
            </div>

            <div className="card4">
              <p className='descrip'>My assignment was due yesterday, and I was in a panic. Thankfully, I found this service, and they saved the day! Amazing!</p>
              <div className="roundphoto"></div>
              <p className='fname'>Chin Woo</p>
              <p className='uname'>@ChinWo453</p>
            </div>

            <div className="card5">
              <p className='descrip'>As my assignment deadline approached, I felt the pressure. Thankfully, a friend recommended GreatScore, and they exceeded my expectations!</p>
              <div className="roundphoto"></div>
              <p className='fname'>Kenvi Sharma</p>
              <p className='uname'>@KenviSharma543</p>
            </div>

            <div className="card6">
              <p className='descrip'>It was amazing experience for me I submitted assignment and I got it back in mail in 3 hour, also interac payment option is available</p>
              <div className="roundphoto"></div>
              <p className='fname'>Keinn Neil</p>
              <p className='uname'>@KKeinn543</p>
            </div>

            <div className="card7">
              <p className='descrip'>As I struggled to meet my assignment deadline, a friend suggested GreatScore. Their efficient service saved the day, leaving me relieved and grateful</p>
              <div className="roundphoto"></div>
              <p className='fname'>Yeni Jem</p>
              <p className='uname'>@Yenisot24</p>
            </div>

            <div className="card8">
              <p className='descrip'>Feeling overwhelmed by a difficult assignment, I turned to GreatScore for help</p>
              <div className="roundphoto"></div>
              <p className='fname'>Lily Se</p>
              <p className='uname'>@LilyS234</p>
            </div>

            <div className="card9">
              <p className='descrip'>Stressed by a challenging assignment, I sought help from GreatScore. Their support eased my burden and ensured a successful outcome</p>
              <div className="roundphoto"></div>
              <p className='fname'>Emma Chole</p>
              <p className='uname'>@EmmaChole341</p>
            </div>

          </div>

        </div>

        <div className="fourthsection">
          <h1 className="instantaccess">Get Instant Access</h1>
          <p className='accessparagraph'>Access affordable assignment assistance, ensuring quality results and peace of work.</p>
          <div className="inputform">
            <input type='text' className="emailbox" placeholder='name@email.com' />
            <button>
              <Link to="/signup" style={{ textDecoration: 'none', color: 'black' }}>Get Access</Link>
            </button>
          </div>
        </div>

        <div className="footer">
          <p>© 2024 GreatScore Inc, All rights reserved.</p>
          <div className="socialmedia">
            <img src={twitterlogo} alt="" ref={twitterref} />
            <img src={instagramlogo} alt="" ref={instagramref} />
            <img src={pinterest} alt="" ref={pinterestref} />
            <img src={linkedinlogo} alt="" ref={linkedinref} />
            <img src={youtubelogo} alt="" ref={youtuberef} />

          </div>
        </div>


      </div>
    </AnimatePresence>
  );
}

export default App;
