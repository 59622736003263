// public/components/ForgotPasswordPage.js
import React, { useState } from 'react';
import axios from 'axios';
import './forgotpasswordpage.css'; // Create this CSS file for styling

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send request to backend to handle forgot password
      await axios.post('https://yourgreatscore.com/api/forgot-password', { email });
      setMessage('If your email is registered, you will receive a password reset link.');
    } catch (error) {
      console.error('Error sending password reset request:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

//   <div className='forgot-password-container'>
//   <h1>Forgot Password</h1>
//   <form onSubmit={handleSubmit}>
//     <div className='input-group'>
//       <label htmlFor='email'>Email:</label>
//       <input
//         type='email'
//         id='email'
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//         required
//       />
//     </div>
//     <button type='submit'>Submit</button>
//     {message && <p>{message}</p>}
//   </form>
// </div>

  return (
   <div className="fgbody">
    <div className="fgwrapper">
      <form onSubmit={handleSubmit}>
        <h1>Forgot Password</h1>
        <div className="fginput-box">
          <input
            type="email"
            placeholder='Email'
            required
            name='email'
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <i className='bx bx-envelope'></i>
        </div>
        {message && <p>{message}</p>}
        <button type='submit'>Submit</button>
       
      </form>
    </div>
   </div>
  );
}

export default ForgotPasswordPage;
