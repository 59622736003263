import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import './loginpagestyle.css';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage(''); // Reset error message on submit

    if (username === "ddddsas" && password === "latest11") {
      localStorage.setItem('username', username);
      navigate('/admin');
    } else {
      axios.post('https://yourgreatscore.com/api/login', { username, password }, { withCredentials: true })
        .then(response => {
          const { data } = response;
          if (data.message === "Success") {
            // alert("Login successful!");
            localStorage.setItem('username', username);
            navigate('/dashboard');
          } else {
            setErrorMessage("Your username or password is incorrect");
          }
        })
        .catch(err => {
          console.error(err);
          setErrorMessage("Your username or password is incorrect");
        });
    }
  };

  const pageVariants = {
    out: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.div initial="out" animate="in" exit="out" variants={pageVariants}>
      <div className='lbody'>
        <div className="lwrapper">
          <form onSubmit={handleSubmit}>
            <h1>Login</h1>
            <div className="linput-box">
              <input
                type="text"
                placeholder='Username'
                required
                name='username'
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <i className='bx bxs-user'></i>
            </div>

            <div className="linput-box">
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder='Password'
                required
                name='password'
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className={passwordVisible ? 'bx bxs-lock-open-alt' : 'bx bxs-lock'}
                onClick={togglePasswordVisibility}
              ></i>
            </div>

            {errorMessage && (
              <div className="error-message" style={{ color: 'yellow', marginBottom: '35px',fontWeight:'bold',fontSize:'13px',marginLeft:'45px' }}>
                {errorMessage}
              </div>
            )}

            <div className="lremember-forgot">
              <label><input type="checkbox" onChange={(e) => setRememberMe(e.target.checked)} />Remember Me</label>
              <a href="/forgot-password">Forgot Password</a>
            </div>

            <button type='submit' className='lsubmitbtnaboutus'>Login</button>

            <div className="lregister-link">
              <p>Don't have an account?
                <Link to="/signup"> Register</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
}

export default LoginPage;
