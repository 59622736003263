import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import './signuppagestyle.css';

function SignupPage() {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [currentBatch, setCurrentBatch] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [otp, setOtp] = useState('');

  const [nameError, setNameError] = useState('');
  const [usernameError, setUsernameError] = useState('');  // Add state for username error
  const [passwordError, setPasswordError] = useState('');

  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'


  const fetchUserData = () => {
    axios.get('https://yourgreatscore.com/api/user', { withCredentials: true })
      .then(response => {
        console.log(`Fetched currentBatch after signup: ${response.data.currentBatch}`);
        setCurrentBatch(response.data.currentBatch);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  };

  const shandleSubmit = (e) => {
    e.preventDefault();

    // Validate name length
    if (name.length < 3 || name.length > 25) {
      setNameError(name.length < 3 ? 'First Name must be at least 3 characters long' : 'Name must be at most 25 characters long');
      return;
    } else {
      setNameError('');
    }

    // Validate password length
    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      return;
    } else {
      setPasswordError('');
    }

    // Ensure all required fields are not empty
    if (!email || !username || !password || !name) {
      return alert('Please fill in all fields');
    }

    axios.post('https://yourgreatscore.com/api/send-otp', { name, username, email, password })
      .then(result => {
        setOtpVisible(true);
        setUsernameError('');
        setMessage('OTP sent to your email');
        setMessageType('success');
      })
      .catch(err => {
        console.error('Error sending OTP:', err.response ? err.response.data : err.message);
        setMessage('Error sending OTP. Please try again.');
        setMessageType('error');
      });
  };

  const handleOtpVerify = (e) => {
    e.preventDefault();
    axios.post('https://yourgreatscore.com/api/verify-otp', { email, otp })
      .then(() => {
        setMessage('OTP verified successfully');
        setMessageType('success');
        navigate('/dashboard'); // Navigate to the dashboard after successful verification
      })
      .catch(err => {
        console.error('OTP verification error:', err.response.data);
        setMessage('Invalid OTP. Please try again.');
        setMessageType('error');
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const pageVariants = {
    out: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.div initial="out" animate="in" exit="out" variants={pageVariants}>
      <div className='sbody'>
        <div className="swrapper">
          <form onSubmit={otpVisible ? handleOtpVerify : shandleSubmit}>
            <h1>Sign Up</h1>

            <div className="sinput-box">
              <input type="text" placeholder='First Name' required
                name='name'
                onChange={(e) => setName(e.target.value)} />
              <i className='bx bxs-user'></i>
              {nameError && <div className="error-message">{nameError}</div>}
            </div>

            <div className="sinput-box">
              <input type="text" placeholder='Username' required
                name='username' autoComplete="username"
                onChange={(e) => setUsername(e.target.value)} />
              <i className='bx bxs-user-circle'></i>
              {usernameError && <div className="error-message">{usernameError}</div>}
            </div>

            <div className="sinput-box">
              <input type="email" placeholder='Email' required
                name='email'
                onChange={(e) => setEmail(e.target.value)} />
              <i className='bx bx-mail-send'></i>
            </div>

            <div className="sinput-box">
              <input type={passwordVisible ? 'text' : 'password'} placeholder='Password' required
                name='password' autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)} />
              <i className={passwordVisible ? 'bx bxs-lock-open-alt' : 'bx bxs-lock'} onClick={togglePasswordVisibility}></i>
              {passwordError && <div className="error-message">{passwordError}</div>}
            </div>

            {otpVisible && (
              <div className="sinput-box">
                <input type="text" placeholder='OTP' required name='otp' onChange={(e) => setOtp(e.target.value)} />
                <i className='bx bx-key'></i>
              </div>
            )}

            {message && <div className={`message ${messageType}`}>{message}</div>}


            <div className="sremember-forgot">
              <label><input type="checkbox" onChange={(e) => setRememberMe(e.target.checked)} />Remember Me</label>
              <a href="/forgot-password">Forgot Password</a>
            </div>

            <button type='submit' className='ssubmitbtnaboutus'>{otpVisible ? 'Verify OTP' : 'Sign Up'}</button>

            <div className="sregister-link">
              <p>Already have an account?
                <Link to="/api/login">
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
}

export default SignupPage;
