import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import './PaymentForm.css';

const PaymentForm = ({ onSuccess, onError, isMobile }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [zipCode, setZipCode] = useState('');
  const [fullName, setFullName] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    setPaymentError(null);

    // Get references to the individual elements
    const cardNumberElement = elements.getElement(CardNumberElement);

    // Create a payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: fullName,
        address: {
          postal_code: zipCode || '', // Use zipCode if available
        },
      },
    });

    if (error) {
      setPaymentError(error.message);
      setIsProcessing(false);
      if (onError) onError(error);
      return;
    }

    // Call your backend to create a payment intent
    try {
      const response = await fetch('https://yourgreatscore.com/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
      });

      const paymentResult = await response.json();

      if (paymentResult.error) {
        setPaymentError(paymentResult.error);
        setIsProcessing(false);
        if (onError) onError(paymentResult.error);
      } else if (paymentResult.requiresAction) {
        // Handle additional authentication
        const { error: confirmError } = await stripe.confirmCardPayment(
          paymentResult.paymentIntentClientSecret
        );

        if (confirmError) {
          setPaymentError(confirmError.message);
          setIsProcessing(false);
          if (onError) onError(confirmError);
        } else {
          // Payment successful
          setIsProcessing(false);
          if (onSuccess) onSuccess();
        }
      } else {
        // Payment successful
        setIsProcessing(false);
        if (onSuccess) onSuccess();
      }
    } catch (err) {
      setPaymentError('Payment processing error');
      setIsProcessing(false);
      if (onError) onError(err);
    }
  };

  // Define base styles for Stripe elements
  const baseStyle = {
    fontSize: '16px',
    color: isMobile ? 'white' : '#ffffff',
    backgroundColor: 'transparent',
    '::placeholder': {
      color: 'gray',
    },
  };

  const invalidStyle = {
    color: '#fa755a',
  };

  return (
    <form onSubmit={handleSubmit} className={`payment-form ${isMobile ? 'mobile' : ''}`}>
      <h3 className="payment-form-title">
        Enter Debit/Credit Information To Continue
      </h3>

      <div className="form-fields-wrapper">
        {isMobile ? (
          <>
            {/* Mobile Layout */}
            {/* First Row - Card Number */}
            <div className="form-row">
              <div className="form-group card-number-group">
                <label>Card Number</label>
                <CardNumberElement
                  className="stripe-element"
                  options={{
                    showIcon: true,
                    style: {
                      base: baseStyle,
                      invalid: invalidStyle,
                    },
                  }}
                />
              </div>
            </div>

            {/* Second Row - Full Name */}
            <div className="form-row">
              <div className="form-group full-name-group">
                <label>Full Name</label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Enter Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
            </div>

            {/* Third Row - CVC and Expiration Date */}
            <div className="form-row">
              <div className="form-group cvc-group">
                <label>CVC</label>
                <CardCvcElement
                  className="stripe-element"
                  options={{
                    style: {
                      base: baseStyle,
                      invalid: invalidStyle,
                    },
                  }}
                />
              </div>
              <div className="form-group expiry-date-group">
                <label>Expiration Date</label>
                <CardExpiryElement
                  className="stripe-element"
                  options={{
                    style: {
                      base: baseStyle,
                      invalid: invalidStyle,
                    },
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Desktop Layout */}
            {/* First Row */}
            <div className="form-row">
              <div className="form-group wide-width">
                <label>Card Number</label>
                <CardNumberElement
                  className="stripe-element"
                  options={{
                    showIcon: true,
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#ffffff',
                        '::placeholder': {
                          color: 'gray',
                        },
                      },
                      invalid: invalidStyle,
                    },
                  }}
                />
              </div>
              <div className="form-group narrow-width">
                <label>Expiration Date</label>
                <CardExpiryElement
                  className="stripe-element"
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#ffffff',
                        '::placeholder': {
                          color: 'gray',
                        },
                      },
                      invalid: invalidStyle,
                    },
                  }}
                />
              </div>
            </div>

            {/* Second Row */}
            <div className="form-row">
              <div className="form-group medium-width">
                <label>Full Name</label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Enter Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  style={{ color: 'white' }}
                  required
                />
              </div>
              <div className="form-group small-width">
                <label>CVC</label>
                <CardCvcElement
                  className="stripe-element"
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#ffffff',
                        '::placeholder': {
                          color: 'gray',
                        },
                      },
                      invalid: invalidStyle,
                    },
                  }}
                />
              </div>
              <div className="form-group narrow-width">
                <label>Zip Code</label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Enter Zip Code"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  style={{ color: 'white' }}
                  required
                />
              </div>
            </div>
          </>
        )}
      </div>

      {paymentError && <div className="payment-error">{paymentError}</div>}

      <div className="button-container">
        <button
          type="submit"
          disabled={!stripe || isProcessing}
          className="pay-button"
        >
          {isProcessing ? 'Processing...' : 'Pay $25'}
        </button>
      </div>
    </form>
  );
};

export default PaymentForm;
